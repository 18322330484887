/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useState,
} from 'react';
import Player from '@vimeo/player';
import htmr from 'htmr';
import { v4 as uuidv4 } from 'uuid';
import { navigate } from 'gatsby';
import { Spin } from 'antd';
import ContentSwitcher from './ContentSwitcher';
import * as styles from '../../styles/learner/ContentTabView.module.scss';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import AssignmentViewer from './AssignmentViewer';
import LessonViewer from './LessonViewer';
import AdjacentTaskNavigator from './AdjacentTaskNavigator';
import ActiveTaskResourceItem from './ActiveTaskResourceItem';
import { fetchCourseRegistrationActvityByTaskId, fetchLastUpdatedCourseRegistrationActivityForRegistration } from '../DataStore/CourseRegistrationActivityService';
import { viewedContent } from '../../services/TrackActionService';
import { TaskType } from '../../models';
import AptitudeViewer from './AptitudeViewer';
import closeIcon from '../../../static/images/admin/close-icon.svg';
import messageIcon from '../../../static/images/learner/message-icon.png';
import LearnerContext from '../../context/Learner/LearnerContext';
import { CourseAccessType, CourseRegistrationStatus } from '../../API';
import { getRegistrationLaunchLink } from './Services/ScormCloudWrapperService';

const VIMEO_BASE = 'https://www.vimeo.com/';
type Props = {
  aptitudeTestData: any,
}
/**
 *
 *
 * @return {*}
 */
const ContentTabView: React.FC<Props> = ({ aptitudeTestData }) => {
  const {
    registration, activeTask, setActiveTask,
    showContentSwitcher, player, setPlayer,
    activitiesPerModule,
  } = useContext(CourseRegistrationContext);
  const { profileInfo } = useContext(LearnerContext);

  const [aptitudeCompleted, setAptitudeCompleted] = useState(false);
  const [showInviteBanner, setShowInviteBanner] = useState(true);
  const [registrationLaunchLink, setRegistrationLaunchLink] = useState('');
  const [iframeLoader, setIframeLoader] = useState(true);

  const isPrivateCourse = registration?.course?.accessType === CourseAccessType.PRIVATE;

  const courseAptitudeInfo = aptitudeTestData?.filter(
    (eachCourseNode: any) => (
      eachCourseNode?.course_slug === registration?.course?.sourceID),
  );

  const moduleActivityArray = activitiesPerModule?.filter(
    (moduleActivity) => moduleActivity.attemptedTasks.length,
  );
  /**
   *
   * finds the task attributes in course registration data
   * by taskId
   * if taskId isn't supplied, returns the module 1, task 1
   * attributes.
   * @param {*} taskId
   */
  const getTaskByTaskId = (taskId?: string) => {
    if (registration) {
      const { course } = registration;
      if (course) {
        for (let i = 0; i < course.modules.length; i += 1) {
          const module = course.modules[i];
          for (let j = 0; j < module.tasks.length; j += 1) {
            const task = module.tasks[j];
            if (taskId) {
              if (task.id === taskId) {
                return ({
                  ...task,
                  taskIndex: j,
                  moduleIndex: i,
                });
              }
            } else {
              return ({
                ...task,
                taskIndex: j,
                moduleIndex: i,
              });
            }
          }
        }
      }
    }
    return null;
  };

  /**
   *
   * fetches the last updated task for course registration from
   * datastore and sets it as an active task, if it exists.
   */
  const setLastUpdatedCourseRegistrationActivity = async () => {
    const lastUpdatedActivity = await
    fetchLastUpdatedCourseRegistrationActivityForRegistration(registration?.id);

    let task;
    if (lastUpdatedActivity) {
      task = getTaskByTaskId(lastUpdatedActivity.taskID);
    } else {
      task = getTaskByTaskId();
    }

    if (task) {
      setActiveTask(task);
    }
  };

  /**
   * creates a vimeo instance with a lesson video uri,
   * or changes the video in the exitsing vimeo instance
   */
  const handleVideoTask = async () => {
    const task = activeTask?.videoTask;
    const videoURI: any = task?.lessonVideo.uri?.split('/')[2];
    const url = `${VIMEO_BASE}${videoURI}`;
    try {
      if (player) {
        await player.destroy();
      }
      const newPlayer = new Player('video-player', {
        url,
        autoplay: false,
        loop: false,
        controls: true,
      });
      setPlayer(newPlayer);
    } catch {
      // console.log(err);
    }
  };

  /**
   *
   *
   */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  /**
   *
   *
   */
  const handleAssignmentTask = () => {
    setPlayer(null);
  };

  const checkingIfAptitudeIsCompleted = async () => {
    const regId = registration?.id;
    const exisitingActivity = await fetchCourseRegistrationActvityByTaskId(regId, regId);
    if (exisitingActivity) {
      setAptitudeCompleted(true);
    }
  };

  const fetchRegistrationLaunchLink = async () => {
    if (isPrivateCourse) {
      const eligibleStatus = [
        CourseRegistrationStatus.APPROVED,
        CourseRegistrationStatus.COMPLETED,
      ];
      if (registration?.status
         && eligibleStatus.includes(registration.status as CourseRegistrationStatus)) {
        try {
          const launchLink = await getRegistrationLaunchLink({
            regId: registration.id,
            enterpriseId: registration.enterpriseID || '',
          });
          setRegistrationLaunchLink(launchLink);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const onLoad = () => {
    if (iframeLoader) {
      setTimeout(() => {
        setIframeLoader(false);
      }, 1500);
    }
  };

  useEffect(() => {
    if (registration) {
      setIframeLoader(true);
      setRegistrationLaunchLink('');
      setLastUpdatedCourseRegistrationActivity();
      checkingIfAptitudeIsCompleted();
      fetchRegistrationLaunchLink();
    }
  }, [registration]);

  useEffect(() => {
    switch (activeTask?.taskType) {
      case TaskType.VIDEO:
        handleVideoTask();
        break;
      case TaskType.ASSIGNMENT:
      case TaskType.FINAL_ASSIGNMENT:
        handleAssignmentTask();
        break;
      default:
        break;
    }
    scrollToTop();
    if (activeTask?.id) {
      viewedContent({
        courseRegistrationId: registration?.id,
        moduleNumber: activeTask?.moduleIndex ? activeTask.moduleIndex + 1 : 1,
        taskNumber: activeTask?.taskIndex ? activeTask.taskIndex + 1 : 1,
        courseName: registration?.course?.courseName,
        courseId: registration?.course?.id,
      });
    }
  }, [activeTask, aptitudeCompleted]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { origin } = window.location;
      if (event.origin !== origin) {
        return;
      }
      const { redirectRoute } = event.data;
      if (redirectRoute) {
        navigate(redirectRoute);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className={`${styles.contentTabView} library-shaw-icon-new`}>
      <div className={styles.contentViewerSection}>
        {registration?.course
        && (
        <>
          {registration?.course?.accessType === CourseAccessType.PRIVATE
            ? (
              <div className={styles.scormVideoPlayer}>
                {registrationLaunchLink
                && (
                <iframe
                  width="100%"
                  height={500}
                  src={registrationLaunchLink}
                  title="title"
                  allowFullScreen
                  frameBorder={0}
                  onLoad={onLoad}
                />
                )}
                {iframeLoader && <Spin className={styles.iframeLoader} size="default" />}
              </div>
            )
            : courseAptitudeInfo?.length && !moduleActivityArray.length && !aptitudeCompleted
              ? (
                <AptitudeViewer
                  registration={registration}
                  aptiTypeformId={courseAptitudeInfo[0]?.typeform_link}
                  setAptitudeCompleted={setAptitudeCompleted}
                  showContentSwitcher={showContentSwitcher}
                />
              )
              : activeTask?.taskType === TaskType.VIDEO
                ? (
                  <LessonViewer
                    handleVideoTask={handleVideoTask}
                  />
                )
                : <AssignmentViewer />}
        </>
        )}
        <div className={`${styles.lessonDetailsSection} ${showContentSwitcher ? '' : styles.active}`}>
          <div className={styles.lessonDetails}>
            <div className={styles.lessonInfoSeciton}>
              <div className={styles.lessonMetadataWrap}>
                {!isPrivateCourse
                && (
                <h4>
                  {activeTask?.taskType === TaskType.VIDEO
                    ? activeTask?.videoTask?.lessonName : ''}
                  {activeTask?.taskType === TaskType.ASSIGNMENT
                    ? activeTask?.assignmentTask?.title : ''}
                  {activeTask?.taskType === TaskType.VIDEO
                    ? activeTask?.finalAssignmentTask?.title : ''}
                </h4>
                )}
              </div>
            </div>
            {activeTask?.taskType === TaskType.VIDEO && (
            <div className={styles.lessonDescriptionSection}>
              <h4>About</h4>
              <div className={styles.lessonDescription}>
                {isPrivateCourse ? registration?.course?.courseDescription
                && htmr(registration?.course?.courseDescription)
                  : activeTask?.videoTask?.lessonDescription
              && htmr(activeTask.videoTask.lessonDescription)}
              </div>
            </div>
            )}
            <AdjacentTaskNavigator />
            {
            profileInfo?.roles?.length > 0 && showInviteBanner
            && (
            <section className={styles.inviteBannerSection}>
              <div className={styles.bannerHeaderSection}>
                <div className={styles.bannerTitleSection}>
                  <div className={styles.inviteBannerIconWrapper}>
                    <img src={messageIcon} alt="Message Icon" />
                  </div>
                  <p className={styles.bannerTitle}>Invite your team members.</p>
                </div>
                <button
                  id="learner-course-registration-invite-banner-close"
                  className={styles.closeIcon}
                  type="button"
                  onClick={() => setShowInviteBanner(false)}
                >
                  <img
                    src={closeIcon}
                    alt="close"
                  />
                </button>
              </div>
              <div className={styles.inviteBannerDetails}>
                <p className={styles.bannerContent}>
                  Get your team set up and running by inviting members directly.
                  They can start enrolling to courses for free for next 28 days.
                </p>
                <button
                  id="learner-course-registration-invite-banner-invite-team"
                  className={styles.inviteCta}
                  type="button"
                  onClick={() => navigate('/admin/accounts')}
                >
                  Invite team

                </button>
              </div>
            </section>
            )
            }
          </div>
          {registration?.course?.accessType === CourseAccessType.PUBLIC
           && activeTask?.videoTask?.lessonResources?.length
            ? (
              <div className={styles.lessonResourceDetails}>
                <h4 className={styles.heading}>
                  Lesson Resources
                </h4>
                <ul>
                  {activeTask?.videoTask?.lessonResources?.map((resource, resourceIndex) => {
                    if (resource?.documentResource) {
                      return (
                        <ActiveTaskResourceItem
                          key={uuidv4()}
                          resource={resource}
                          resourceIndex={resourceIndex}
                        />
                      );
                    }
                    return <></>;
                  })}
                </ul>
              </div>
            ) : <></>}
        </div>
      </div>
      {registration?.course?.accessType === CourseAccessType.PUBLIC
      && (
      <ContentSwitcher
        setAptitudeCompleted={setAptitudeCompleted}
      />
      )}
    </div>
  );
};

export default ContentTabView;
