import Player from '@vimeo/player';
import React, { useState } from 'react';
import {
  CourseRegistration,
} from '../../models';
import { CourseRegistrationWithCourse, ActiveTaskType, ActivitiesPerModuleType } from '../../types/commons';

const defaultState = {};

type Props = {
  children?: React.ReactNode
}

type AdjacentTasksType = {
  prevTask?: ActiveTaskType | null,
  nextTask?: ActiveTaskType | null,
}

export type CourseRegistrationContextType = {
  activeTab: string, // TODO: enums
  setActiveTab: (t: string) => void,
  registration: CourseRegistrationWithCourse | null,
  setRegistration: (r: CourseRegistrationWithCourse) => void,
  player: Player | null,
  setPlayer: (p: Player | null) => void,
  activeTask: ActiveTaskType | null,
  setActiveTask: (a: ActiveTaskType) => void,
  showContentSwitcher: boolean,
  setShowContentSwitcher: (b: boolean) => void,
  activitiesPerModule: ActivitiesPerModuleType,
  setActivitiesPerModule: (a: ActivitiesPerModuleType) => void,
  adjacentTasks: AdjacentTasksType,
  setAdjacentTasks: (a: AdjacentTasksType) => void
}
const CourseRegistrationContext = React.createContext<
CourseRegistrationContextType>(defaultState as CourseRegistrationContextType);

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
const CourseRegistrationProvider: React.FC<Props> = (props) => {
  const { children } = props;
  const [activeTab, setActiveTab] = useState<string>('content');
  const [registration, setRegistration] = useState<CourseRegistration | null>(null);
  const [player, setPlayer] = useState<Player | null>(null);
  const [activeTask, setActiveTask] = useState<ActiveTaskType | null>(null);
  const [showContentSwitcher, setShowContentSwitcher] = useState<boolean>(false);
  const [activitiesPerModule, setActivitiesPerModule] = useState<ActivitiesPerModuleType>([]);
  const [adjacentTasks, setAdjacentTasks] = useState<AdjacentTasksType>({
    prevTask: null,
    nextTask: null,
  });

  return (
    <CourseRegistrationContext.Provider
      value={{
        activeTab,
        setActiveTab,
        registration,
        setRegistration,
        player,
        setPlayer,
        activeTask,
        setActiveTask,
        showContentSwitcher,
        setShowContentSwitcher,
        activitiesPerModule,
        setActivitiesPerModule,
        adjacentTasks,
        setAdjacentTasks,
      }}
    >
      {children}
    </CourseRegistrationContext.Provider>
  );
};

export default CourseRegistrationContext;

export { CourseRegistrationProvider };
