import React from 'react';
import { Collapse } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import * as styles from '../../styles/learner/CourseMaterialsPack.module.scss';
import LessonResourceItem from './LessonResourceItem';
import { Task, TaskType } from '../../models';

const { Panel } = Collapse;

type Props = {
  tasks: Array<Task>
}

/**
 *
 *
 * @return {*}
 */
const CourseMaterialsPack: React.FC<Props> = ({ tasks }) => {
  const lessons = tasks.filter((task) => task.taskType === TaskType.VIDEO);

  return (
    <Collapse className={styles.courseMaterialsPack} defaultActiveKey={lessons?.[0]?.id} accordion>
      {lessons.map((lesson, lessonIndex) => (
        <Panel
          header={`Lesson ${lessonIndex + 1}`}
          showArrow={false}
          key={lesson?.id}
          className={styles.courseMaterial}
        >
          <ul className={styles.lessonResourcesWrapper}>
            {lesson?.videoTask?.lessonResources?.map((resource, index) => (
              <LessonResourceItem key={uuidv4()} resource={resource} resourceIndex={index} />
            ))}
          </ul>
        </Panel>
      ))}
    </Collapse>
  );
};
export default CourseMaterialsPack;
