// extracted by mini-css-extract-plugin
export var actionContainer = "CertificateActions-module--actionContainer--1qlAh";
export var heading = "CertificateActions-module--heading--3olA1";
export var info = "CertificateActions-module--info--2B9wK";
export var downloadCta = "CertificateActions-module--downloadCta--F7ekw";
export var linkedInShare = "CertificateActions-module--linkedInShare--27-wM";
export var shareCertificateTitle = "CertificateActions-module--shareCertificateTitle--21RWA";
export var socialMediaIconsConatiner = "CertificateActions-module--socialMediaIconsConatiner--12-CT";
export var socialIconShare = "CertificateActions-module--socialIconShare--3a29Z";
export var copyButtonContainer = "CertificateActions-module--copyButtonContainer---bFRS";
export var regerateCertificateCta = "CertificateActions-module--regerateCertificateCta--3xaxO";