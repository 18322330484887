// extracted by mini-css-extract-plugin
export var certificateListItem = "FinalAssignmentItem-module--certificateListItem--187H-";
export var imageWrap = "FinalAssignmentItem-module--imageWrap--h1WrD";
export var hasBadge = "FinalAssignmentItem-module--hasBadge--3c3Ww";
export var fail = "FinalAssignmentItem-module--fail--1yRJF";
export var contentWrap = "FinalAssignmentItem-module--contentWrap--9Slas";
export var moduleNumber = "FinalAssignmentItem-module--moduleNumber--suSjd";
export var title = "FinalAssignmentItem-module--title--1coZe";
export var buttonWrapper = "FinalAssignmentItem-module--buttonWrapper--3YdId";
export var cta = "FinalAssignmentItem-module--cta--2-dHc";
export var startAssignment = "FinalAssignmentItem-module--startAssignment--1m-kY";
export var scoreWrapper = "FinalAssignmentItem-module--scoreWrapper--2iHZd";
export var score = "FinalAssignmentItem-module--score--22_pn";
export var grade = "FinalAssignmentItem-module--grade--1iH3v";
export var moduleProgressBar = "FinalAssignmentItem-module--moduleProgressBar--2k0q1";
export var progress = "FinalAssignmentItem-module--progress--3zVRc";