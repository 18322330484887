import React, { useContext, useEffect, useState } from 'react';
import * as styles from '../../styles/learner/FinalAssignmentItem.module.scss';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import { fetchAssignmentResponseForCourseByTaskId } from '../DataStore/Services';
import { AssignmentResponse } from '../../models';
import { ActiveTaskType, CourseGrade as Grade } from '../../types/commons';
import { getGradeFromPercentage } from './Services/CourseRegistrationService';

type Props = {
  task: ActiveTaskType,
  moduleNumber: number,
  switchTab: (t: string) => void,
}
/**
 *
 *
 * @return {*}
 */
const FinalAssignmentItem: React.FC<Props> = ({ task, moduleNumber, switchTab }) => {
  const { setActiveTask, registration } = useContext(CourseRegistrationContext);
  const [assignmentResponse, setAssignmentResponse] = useState<AssignmentResponse | null>(null);
  const openAssignment = () => {
    setActiveTask(task);
    switchTab('content');
  };

  const checkAssignmentCompletion = async () => {
    const regId = registration?.id;
    const taskId = task.id;
    const finalAssignmentResponse = await fetchAssignmentResponseForCourseByTaskId(regId, taskId);
    if (finalAssignmentResponse) {
      setAssignmentResponse(finalAssignmentResponse);
    }
  };

  const getAssignmentGrade = () => {
    if (assignmentResponse) {
      const percentage = Math.ceil((assignmentResponse?.score
        / assignmentResponse?.totalScore) * 100);
      return getGradeFromPercentage(percentage);
    }
    return null;
  };

  useEffect(() => {
    if (task) {
      checkAssignmentCompletion();
    }
  }, [task]);

  const assignmentGrade = getAssignmentGrade();

  return (
    <div key={task?.finalAssignmentTask?.formId} className={styles.certificateListItem}>
      <div className={`${styles.imageWrap} ${assignmentGrade === Grade.FAIL ? styles.fail : ''
      } ${assignmentGrade
        && [Grade.MERIT, Grade.PASS, Grade.DISTINCTION].includes(assignmentGrade) ? styles.hasBadge : ''}
      `}
      >
        <img
          src={`/images/learner/badge-${assignmentGrade?.toLowerCase() || 'placeholder'}.svg`}
          alt="medal"
        />
      </div>
      <div className={styles.contentWrap}>
        <p className={styles.moduleNumber}>
          Module
          {' '}
          {moduleNumber}
        </p>
        <p className={styles.title}>
          Final Assignment
        </p>
      </div>
      <div className={styles.scoreWrapper}>
        {assignmentResponse
          ? (
            <>
              <p className={styles.score}>
                Score:
                {' '}
                {Math.ceil((assignmentResponse.score / assignmentResponse.totalScore) * 100)}
                {' '}
                %
              </p>
              <div className={styles.moduleProgressBar}>
                <div
                  className={`${styles.progress} progress-selector`}
                  style={{
                    width: `${Math.ceil((
                      assignmentResponse.score / assignmentResponse.totalScore) * 100)}%`,
                  }}
                />
              </div>
            </>
          )
          : (
            <>
              <p className={styles.score}>
                Not yet started
              </p>
              <div className={styles.moduleProgressBar}>
                <div
                  className={styles.progress}
                />
              </div>
            </>
          )}
      </div>
      <div className={styles.buttonWrapper}>
        <button
          type="button"
          onClick={openAssignment}
          className={styles.cta}
        >
          {assignmentResponse
            ? (
              <div>
                <img src="/images/learner/redo.png" alt="retake" />
                <p>Retake</p>
              </div>
            ) : (
              <div>
                <img src="/images/learner/assignment.png" alt="start" />
                <p className={styles.startAssignment}>Start Assignment</p>
              </div>
            )}
        </button>
      </div>
    </div>
  );
};

export default FinalAssignmentItem;
