// extracted by mini-css-extract-plugin
export var svgWrapper = "CertificateGenerator-module--svgWrapper--BJSqL";
export var certificateContainer = "CertificateGenerator-module--certificateContainer--YG-5L";
export var certificateWrapper = "CertificateGenerator-module--certificateWrapper--tXVYa";
export var brandLogo = "CertificateGenerator-module--brandLogo--2nUSy";
export var recommendationText = "CertificateGenerator-module--recommendationText--2FNhr";
export var fillerText = "CertificateGenerator-module--fillerText--Wetys";
export var courseName = "CertificateGenerator-module--courseName--2px0R";
export var userName = "CertificateGenerator-module--userName--2Azap";
export var upskillCert = "CertificateGenerator-module--upskillCert--3grpD";
export var gradeDetails = "CertificateGenerator-module--gradeDetails--17HGr";
export var certDetails = "CertificateGenerator-module--certDetails--1Ieei";
export var awdDetails = "CertificateGenerator-module--awdDetails--2EbMi";
export var certificateLogos = "CertificateGenerator-module--certificateLogos--cvDue";
export var hcdLogo = "CertificateGenerator-module--hcdLogo--1n29V";
export var cpdLogo = "CertificateGenerator-module--cpdLogo--35IYK";