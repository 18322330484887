import React from 'react';
import PropTypes from 'prop-types';
import htmr from 'htmr';
import { v4 as uuidv4 } from 'uuid';
import * as styles from '../../styles/learner/StarterKit.module.scss';
import { Course } from '../../models';

type Props = {
  course?: Course & {
    // addition props go here
  } | any,
}

/**
 *
 *
 * @return {*}
 */
const StarterKit: React.FC<Props> = ({ course }) => {
  /**
   *
   *
   * @param {*} item
   */
  const downloadStarterPackItem: (item: string | undefined) => void = (item) => {
    window.open(item, '__blank');
  };

  return (
    <div className={styles.shawStarterPack}>
      <div className={styles.starterPackBackground}>
        <img
          src={course?.courseHeroBannerImage?.href}
          alt={course?.courseHeroBannerImage?.title}
        />
      </div>
      <div className={styles.starterPackContent}>
        <p className={styles.starterPackHeader}>
          {course?.courseName}
        </p>
        <div className={styles.starterPackInfo}>
          {course?.courseDescription && htmr(course?.courseDescription)}
        </div>
        <div className={styles.starterPackFiles}>
          {course?.starterPack?.items.map((item: any) => (
            item.resourceType === 'DOCUMENT' ? (
              <div className={styles.singlePack} key={uuidv4()}>
                <p>{item?.documentResource?.link?.title}</p>
                <div className={styles.buttonWrapper}>
                  <button type="button" onClick={() => downloadStarterPackItem(item?.documentResource?.link?.href)}>
                    Download
                  </button>
                </div>
              </div>
            )
              : (
                <div className={styles.singlePack} key={uuidv4()}>
                  <p>{item?.videoResource?.name}</p>
                  <div className={styles.buttonWrapper}>
                    <button type="button">
                      Play
                    </button>
                  </div>
                </div>
              )
          ))}
        </div>
      </div>
    </div>
  );
};

StarterKit.defaultProps = {
  course: {},
};

StarterKit.propTypes = {
  course: PropTypes.instanceOf(Course),
};

export default StarterKit;
