import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import * as styles from '../../styles/learner/AssignmentAttempted.module.scss';
import { fetchAssignmentResponseForCourseByTaskId } from '../DataStore/Services';
import { AssignmentResponse, TaskType } from '../../models';
import { getGradeFromPercentage } from './Services/CourseRegistrationService';

type Props = {
  retakeAssignment: () => void,
}

/**
 *
 *
 * @return {*}
 */
const AssignmentAttempted: React.FC<Props> = ({ retakeAssignment }) => {
  const { activeTask, registration } = useContext(CourseRegistrationContext);
  const [result, setResult] = useState<AssignmentResponse | null>(null);
  /**
   *
   *
   */
  const fetchAssignmentResult = async () => {
    const regId = registration?.id;
    const taskId = activeTask?.id;
    const assignmentResult = await fetchAssignmentResponseForCourseByTaskId(regId, taskId);
    setResult(assignmentResult);
  };

  useEffect(() => {
    fetchAssignmentResult();
  }, []);
  let percentage;
  if (result) {
    percentage = Math.ceil((result?.score / result?.totalScore) * 100);
  }
  return (
    <div className={styles.assignmentAttempted}>
      <p>Your Assignment has been reviewed</p>
      {result && (
        activeTask?.taskType === TaskType.FINAL_ASSIGNMENT && percentage
          ? (
            <>
              <p>
                Your score is:
                {' '}
                {percentage}
                %
              </p>
              <p>
                Your grade is:
                {' '}
                {getGradeFromPercentage(percentage)}
              </p>
            </>
          )
          : (
            <p>
              Your score is
              {' '}
              {`${result?.score}/${result?.totalScore}`}
            </p>
          )
      )}
      <button type="button" onClick={retakeAssignment}>Retake</button>
    </div>
  );
};

AssignmentAttempted.propTypes = {
  retakeAssignment: PropTypes.func.isRequired,
};

export default AssignmentAttempted;
