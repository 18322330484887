import React from 'react';
import * as styles from '../../styles/learner/AptitudeItem.module.scss';
import { AptitudeResult } from '../../types/commons';

type Props = {
  aptitudeDetails: AptitudeResult
}

const AptitudeItem:React.FC<Props> = ({ aptitudeDetails }) => (
  <div className={styles.certificateListItem}>
    <div className={styles.imageWrap}>
      <img
        src="/images/learner/badge-merit.svg"
        alt="Badge placeholder"
      />
    </div>
    <div className={styles.contentWrap}>
      <p className={styles.title}>
        Aptitude Test
      </p>
    </div>
    <div className={styles.scoreWrapper}>
      <p className={styles.score}>
        Score:
        {' '}
        {Math.ceil((aptitudeDetails.score / aptitudeDetails.totalScore) * 100)}
        {' '}
        %
      </p>
      <div className={styles.moduleProgressBar}>
        <div
          className={styles.progress}
          style={{
            width: `${Math.ceil((
              aptitudeDetails.score / aptitudeDetails.totalScore) * 100)}%`,
          }}
        />
      </div>
    </div>
  </div>
);

export default AptitudeItem;
