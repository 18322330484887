import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import { graphql } from 'gatsby';
import Authenticate from '../../../components/Identity/Authenticate';
import CourseRegistrationWrapper from '../../../components/Learner/CourseRegistrationWrapper';
import { viewedPage } from '../../../services/TrackActionService';
import { PageKind } from '../../../components/Learner/Services/LearnerNavigations';

interface Props {
  data:any,
}
/**
 *
 *
 * @return {*}
 */
const CourseRegistration: React.FC<Props> = ({ data }): any => {
  const aptitudeTestData = data?.allAptitudeTestJson?.nodes;

  useEffect(() => {
    viewedPage({
      pageKind: PageKind.COURSE_REGISTRATION,
    });
  }, []);
  return (
    <Router basepath="/learner/course-registration">
      <CourseRegistrationWrapper
        aptitudeTestData={aptitudeTestData}
        path="/:regId/:tab"
      />
      <CourseRegistrationWrapper
        aptitudeTestData={aptitudeTestData}
        default
      />
    </Router>
  );
};

export default Authenticate(CourseRegistration);

export const pageQuery = graphql`
query AptitudeTestJson {
  allAptitudeTestJson {
    nodes {
        course_slug
        typeform_link
    }
  }
}`;
