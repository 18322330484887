import React from 'react';
import { CourseRegistrationProvider } from '../../context/Learner/CourseRegistrationContext';
import { LearnerProvider } from '../../context/Learner/LearnerContext';
import CourseRegistrationDetailView from './CourseRegistrationDetailView';

type Props = {
  regId?: string,
  tab?: string,
  path?: string,
  default?: boolean,
  aptitudeTestData: any,
}
/**
 *
 *
 * @return {*}
 */
const CourseRegistrationWrapper: React.FC<Props> = (props) => {
  const {
    regId,
    tab,
    aptitudeTestData,
  } = props;
  return (
    <LearnerProvider>
      <CourseRegistrationProvider>
        <CourseRegistrationDetailView
          regId={regId}
          tab={tab}
          aptitudeTestData={aptitudeTestData}
        />
      </CourseRegistrationProvider>
    </LearnerProvider>
  );
};

export default CourseRegistrationWrapper;
