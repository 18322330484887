// extracted by mini-css-extract-plugin
export var contentTabView = "ContentTabView-module--contentTabView--3R1qU";
export var contentViewerSection = "ContentTabView-module--contentViewerSection--1HOai";
export var lessonDetailsSection = "ContentTabView-module--lessonDetailsSection--OLwtw";
export var lessonDetails = "ContentTabView-module--lessonDetails--2Fkxh";
export var lessonInfoSeciton = "ContentTabView-module--lessonInfoSeciton--32WBE";
export var lessonMetadataWrap = "ContentTabView-module--lessonMetadataWrap--2Lkjp";
export var lessonDescriptionSection = "ContentTabView-module--lessonDescriptionSection--3NYIY";
export var lessonDescription = "ContentTabView-module--lessonDescription--1oxQI";
export var cta = "ContentTabView-module--cta--3Ej7d";
export var inviteBannerSection = "ContentTabView-module--inviteBannerSection--VhZy5";
export var bannerHeaderSection = "ContentTabView-module--bannerHeaderSection--lxNfj";
export var bannerTitleSection = "ContentTabView-module--bannerTitleSection--39Roc";
export var inviteBannerIconWrapper = "ContentTabView-module--inviteBannerIconWrapper--u6CPs";
export var bannerTitle = "ContentTabView-module--bannerTitle--3Zqf5";
export var closeIcon = "ContentTabView-module--closeIcon--HQfLn";
export var inviteBannerDetails = "ContentTabView-module--inviteBannerDetails--1tmHf";
export var bannerContent = "ContentTabView-module--bannerContent--3tmse";
export var inviteCta = "ContentTabView-module--inviteCta--3vwJL";
export var lessonResourceDetails = "ContentTabView-module--lessonResourceDetails--jY-xh";
export var heading = "ContentTabView-module--heading--3zr_z";
export var active = "ContentTabView-module--active--2HU5I";
export var scormVideoPlayer = "ContentTabView-module--scormVideoPlayer--2fGTB";
export var iframeLoader = "ContentTabView-module--iframeLoader--1Yw3w";