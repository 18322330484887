// extracted by mini-css-extract-plugin
export var summary = "CourseProgressDisplay-module--summary--2vkFx";
export var assessmentsCourseProgressIndicator = "CourseProgressDisplay-module--assessmentsCourseProgressIndicator--10YwG";
export var progressRing = "CourseProgressDisplay-module--progressRing--1utRc";
export var full = "CourseProgressDisplay-module--full--2HbSr";
export var active = "CourseProgressDisplay-module--active--1WR0F";
export var half = "CourseProgressDisplay-module--half--1rtIC";
export var quarter = "CourseProgressDisplay-module--quarter--qwODO";
export var part1 = "CourseProgressDisplay-module--part1--Mp8zT";
export var scoreHeading = "CourseProgressDisplay-module--scoreHeading--3mR0d";
export var part2 = "CourseProgressDisplay-module--part2---o1bD";
export var subjectHeading = "CourseProgressDisplay-module--subjectHeading--1DAT2";
export var subject = "CourseProgressDisplay-module--subject--d_y_D";
export var certifiedWrapper = "CourseProgressDisplay-module--certifiedWrapper--poeHx";
export var certified = "CourseProgressDisplay-module--certified--2VsGg";
export var cpdLogoSection = "CourseProgressDisplay-module--cpdLogoSection--2cocf";
export var completion = "CourseProgressDisplay-module--completion--1wOkV";
export var continueBtn = "CourseProgressDisplay-module--continueBtn--3qrbc";
export var cutout = "CourseProgressDisplay-module--cutout--2EgG_";
export var legend = "CourseProgressDisplay-module--legend--2Q7KI";
export var legendList = "CourseProgressDisplay-module--legendList--2DCge";