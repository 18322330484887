import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Tabs } from 'antd';
import { DataStore } from 'aws-amplify';
import dayjs from 'dayjs';
import * as styles from '../../styles/learner/CourseRegistration.module.scss';
import Layout from './Layout';
import ContentTabView from './ContentTabView';
import CertificatesTabView from './CertificatesTabView';
import CourseMaterialsTabView from './CourseMaterialsTabView';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import { navigateTo, PageKind } from './Services/LearnerNavigations';
import { fetchCourseRegistrationActvitiesByRegistrationId } from '../DataStore/CourseRegistrationActivityService';
import { ActivitiesPerModuleType } from '../../types/commons';
import {
  CourseAccessType,
  CourseRegistration, CourseRegistrationActivity,
  CourseRegistrationActivityStatus, CourseRegistrationStatus,
} from '../../models';
import { checkCourseCompletion } from './Services/CourseRegistrationService';
import LearnerContext from '../../context/Learner/LearnerContext';
import {
  isBDMUser, postEvent, toaster,
} from '../../services/utils';
import { getCourseById, updateCourseRegistration } from '../GraphQL/Services';
import { registerCourseInScormCloud } from './Services/ScormCloudWrapperService';

const { TabPane } = Tabs;

type Props = {
  regId?: string,
  tab?: string,
  aptitudeTestData: any,
}
/**
 *
 *
 * @return {*}
 */
const CourseRegistrationDetailView: React.FC<Props> = (props) => {
  const {
    regId, tab, aptitudeTestData,
  } = props;
  const {
    activeTab,
    setActiveTab,
    registration,
    setRegistration,
    setActivitiesPerModule,
  } = useContext(CourseRegistrationContext);

  const { profileInfo } = useContext(LearnerContext);

  /**
   *
   * fetch current course registration by ID.
   */
  const fetchRegistrationData = async () => {
    try {
      if (regId) {
        const reg = await DataStore.query(CourseRegistration, regId);
        if (reg?.courseID) {
          const course = await getCourseById(reg.courseID);
          const courseRegistration = { ...reg, course };
          setRegistration(courseRegistration);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   *
   *
   */
  const segreateActivtiesPerModule = (activities: Array<CourseRegistrationActivity>) => {
    const activitiesForEachModule = [] as ActivitiesPerModuleType;
    registration?.course?.modules.forEach((module) => {
      const totalTasks = module.tasks.length;
      const attemptedTasks = [] as Array<CourseRegistrationActivity & {taskIndex: number}>;
      module.tasks.forEach((task, taskIndex) => {
        activities.forEach((activity) => {
          if (activity.taskID === task.id) {
            attemptedTasks.push({
              taskIndex,
              ...activity,
            });
          }
        });
      });
      activitiesForEachModule.push({
        totalTasks,
        attemptedTasks,
      });
    });
    setActivitiesPerModule(activitiesForEachModule);
  };

  /**
   * fetches all registration activities
   *
   */
  const fetchRegistrationActivityData = async () => {
    const activities = await fetchCourseRegistrationActvitiesByRegistrationId(regId);
    segreateActivtiesPerModule(activities);
  };

  const updateCourseRegistrationStatusForAssignedCourse = async () => {
    if (registration?.status === CourseRegistrationStatus.ASSIGNED) {
      await updateCourseRegistration(
        registration?.id,
        registration?.courseID,
        // eslint-disable-next-line no-underscore-dangle
        (registration as any)?._version,
        CourseRegistrationStatus.APPROVED,
      );
      if (registration?.course?.accessType === CourseAccessType.PRIVATE) {
        await registerCourseInScormCloud({
          enterpriseId: profileInfo?.enterpriseID || '',
          courseId: registration?.courseID,
          registrationId: registration?.id,
          userId: profileInfo.id,
        });
      }
      setRegistration({
        ...registration,
        status: CourseRegistrationStatus.APPROVED,
      });

      toaster('Course Started');

      const payload = {
        event: 'Course Started',
        userId: profileInfo?.id,
        enterpriseId: profileInfo?.enterpriseID,
        originalTimestamp: dayjs().toISOString(),
        sentAt: dayjs().toISOString(),
        properties: {
          courseId: registration?.courseID,
          courseSource: registration?.course?.source,
          courseSourceId: registration?.course?.sourceID,
          courseRegistrationId: registration?.id,
          courseTitle: registration?.course?.courseName,
          exclude: isBDMUser(profileInfo.email),
          courseAccessType: registration?.course?.accessType,
        },
      } as any;
      postEvent(payload);
    }
  };

  useEffect(() => {
    if (registration?.id && profileInfo?.id) {
      fetchRegistrationActivityData();
      updateCourseRegistrationStatusForAssignedCourse();
    }
  }, [registration, profileInfo]);

  useEffect(() => {
    if (!regId) {
      navigateTo(PageKind.HOME);
    } else {
      fetchRegistrationData();
      checkCourseCompletion(regId);
      const subscription = DataStore.observe(CourseRegistrationActivity,
        (activity) => activity.courseRegistrationID('eq', regId)).subscribe((msg) => {
        if (msg.element.status === CourseRegistrationActivityStatus.COMPLETED) {
          checkCourseCompletion(regId);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
    if (tab) {
      setActiveTab(tab);
    }
    return () => {};
  }, [regId]);

  /**
   *
   *
   * @param {*} selectedTab
   */
  const switchTab = (selectedTab: string) => {
    setActiveTab(selectedTab);
    navigate(`../${selectedTab}/`);
  };

  return (
    <Layout>
      {registration?.course
        ? (
          <div className={styles.courseRegistration}>
            <div className={styles.courseNameContainer}>
              <p className={styles.courseName}>
                {registration?.course?.courseName}
              </p>
            </div>
            <Tabs defaultActiveKey="content" activeKey={activeTab} className={styles.tabs} onChange={switchTab}>
              <TabPane tab="Content" key="content">
                <ContentTabView
                  aptitudeTestData={aptitudeTestData}
                />
              </TabPane>
              {registration?.course?.accessType === CourseAccessType.PUBLIC && (
              <>
                <TabPane tab="Course Materials" key="course-materials">
                  <CourseMaterialsTabView />
                </TabPane>
                <TabPane tab="Certificates" key="certificates">
                  <CertificatesTabView switchTab={switchTab} />
                </TabPane>
              </>
              )}
            </Tabs>
          </div>
        )
        : null}
    </Layout>
  );
};

export default CourseRegistrationDetailView;
