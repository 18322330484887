import React, {
  useContext, useEffect, useState,
} from 'react';
import { Storage } from 'aws-amplify';
import LearnerContext from '../../context/Learner/LearnerContext';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import * as styles from '../../styles/learner/CertificateDisplay.module.scss';
import { fetchAssignmentResponseForCourseByTaskId } from '../DataStore/Services';
import {
  AssignmentResponse, CourseModule, Task,
} from '../../models';
import CertificateViewer from './CertificateViewer';
import CertificatePlaceholder from './CertificatePlaceholder';
import CertificateGenerator from './CertificateGenerator';
import CertificateActions from './CertificateActions';
import { checkCertificateByKey, getGradeFromPercentage } from './Services/CourseRegistrationService';
import { CourseGrade } from '../../types/commons';

enum DiplomaState {
  NOT_ELIGIBLE, // will show a placeholder
  NOT_GENERATED, // will generate a certificate
  GENERATED, // will show the generated certificate
}

interface Props {
  certificateReady: boolean,
  setCertificateReady: React.Dispatch<React.SetStateAction<boolean>>,
}

/**
 *
 *
 * @return {*}
 */
const CertificateDisplay: React.FC<Props> = ({
  certificateReady,
  setCertificateReady,
}) => {
  const { profileInfo } = useContext(LearnerContext);
  const { registration, activeTab } = useContext(CourseRegistrationContext);
  const [certificateState, setCertificateState] = useState(DiplomaState.NOT_ELIGIBLE);
  const [certificate, setCertificate] = useState<string | null>(null);
  const [courseGrade, setCourseGrade] = useState<CourseGrade | null>(null);

  /**
   *
   * fetches the certificate from s3
   */
  const fetchCertificate = async () => {
    const certificateRes = await Storage.get(`certficates/${registration?.id}/certificate`, { level: 'public' });
    if (certificateRes) {
      setCertificate(certificateRes as string);
      setCertificateState(DiplomaState.GENERATED);
    }
  };

  const checkEligibilityToViewCertificate = (
    finalAssignmentResponse:Array<AssignmentResponse | null>,
  ) => {
    if (!finalAssignmentResponse?.length) return false;
    return finalAssignmentResponse.every((assignment) => {
      if (!assignment?.score) return false;
      const score = Math.ceil((assignment?.score / assignment?.totalScore) * 100);
      if (score < 50) return false;
      return true;
    });
  };

  /**
   * fetches the scores for all final assignments
   * and calucates the grade for the course
   * registration.
   *
   */
  const calculateCourseGrade: () => Promise<boolean | null> = async () => {
    const courseData = registration?.course;
    if (courseData) {
      const regId = registration?.id;
      const finalAssignmentResponsePromises = courseData.modules
        .reduce((acc: Array<Promise<AssignmentResponse | null>>, cur: CourseModule) => {
          cur.tasks.forEach((task: Task) => {
            if (task.finalAssignmentTask) {
              const taskId = task.id;
              const promise = fetchAssignmentResponseForCourseByTaskId(regId, taskId);
              acc.push(promise);
            }
          });
          return acc;
        }, []);
      return Promise.all(finalAssignmentResponsePromises).then((responses) => {
        const totalAssignments = responses?.length;
        const totalPercentage = responses?.reduce((acc, cur) => {
          if (cur?.score && cur?.totalScore) {
            const percentageForAssignment = (cur?.score / cur?.totalScore) * 100;
            return acc + percentageForAssignment;
          }
          return acc;
        }, 0);
        const coursePercentage = Math.ceil(totalPercentage / totalAssignments);
        const grade = getGradeFromPercentage(coursePercentage);
        setCourseGrade(grade);
        const isEligibleToGenerateCertificate = checkEligibilityToViewCertificate(responses);
        return isEligibleToGenerateCertificate;
      });
    }
    return null;
  };

  /**
   *
   *
   */
  const triggerCertificateGeneration = async () => {
    setCertificate(null);
    setCertificateState(DiplomaState.NOT_GENERATED);
  };

  /**
   *
   * fetches the certificates if it exists
   * else, generates the certificate.
   */
  const checkCertificateEligiblity = async () => {
    // aws Storage doesn't have a way to determine the existance of an object.
    // tweak once issue is fixed.
    const key = `certficates/${registration?.id}/certificate`;
    try {
      const elements = await checkCertificateByKey(key);
      if (elements) {
        fetchCertificate();
      }
    } catch (err) {
      // certificate does exit in storage.
      console.error('Error: ', err);
      triggerCertificateGeneration();
    }
  };

  /**
   *
   * checks if all final assignments are complete.
   */
  const checkCourseCompletion = async () => {
    // TODO: fetch the status for all final assignments.
    const isEligibleToViewCertificate = await calculateCourseGrade();
    if (isEligibleToViewCertificate) {
      checkCertificateEligiblity();
    }
  };

  useEffect(() => {
    if (registration?.id && profileInfo && activeTab === 'certificates') {
      setCertificateState(DiplomaState.NOT_ELIGIBLE);
      setCertificate(null);
      checkCourseCompletion();
    }
  }, [registration, activeTab]);

  return (
    <div className={styles.certificateDisplay}>
      <div className={styles.certificateContainer}>
        {certificateState === DiplomaState.NOT_ELIGIBLE
         && <CertificatePlaceholder />}
        {certificateState === DiplomaState.GENERATED
        && (
        <CertificateViewer
          setCertificateReady={setCertificateReady}
          certificate={certificate}
        />
        )}
        {certificateState === DiplomaState.NOT_GENERATED
        && (
        <CertificateGenerator
          grade={courseGrade}
          fetchCertificate={fetchCertificate}
        />
        )}
      </div>
      {certificateState === DiplomaState.GENERATED && (
      <CertificateActions
        setCertificateReady={setCertificateReady}
        certificateReady={certificateReady}
        regerateCertificate={triggerCertificateGeneration}
      />
      )}
    </div>
  );
};

export default CertificateDisplay;
