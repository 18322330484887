/* eslint-disable no-restricted-syntax */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Widget } from '@typeform/embed-react';
import dayjs from 'dayjs';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import AssignmentAttempted from './AssignmentAttempted';
import * as styles from '../../styles/learner/AssignmentViewer.module.scss';
import LearnerContext from '../../context/Learner/LearnerContext';
import { trackCourseRegistrationActivity } from './Services/CourseRegistrationService';
import { CourseRegistrationActivityStatus, TaskType } from '../../models';
import { fetchCourseRegistrationActvityByTaskId } from '../DataStore/Services';
import { taskStarted } from '../../services/TrackActionService';
import { isBDMUser, postEvent } from '../../services/utils';

enum ASSIGNMENT_STATE {
  ACTIVE, // allows the user to take the assignment
  DISABLED, // shows an in review screen
  RESULT, // shows the assignment result screen
}

export type TypeformRequest = {
  moduleNumber: number,
  moduleName: string,
  moduleSlug: string,
  assignmentNumber: number,
  assignmentType: string,
  facultySlug: string,
}

/**
 *
 *
 * @return {*}
 */
const AssignmentViewer: React.FC = () => {
  const {
    activeTask, registration,
    adjacentTasks, setActiveTask, activitiesPerModule,
  } = useContext(CourseRegistrationContext);
  const { profileInfo } = useContext(LearnerContext);
  const [loading, setLoading] = useState(true);
  const [formId, setFormId] = useState<string | null>(null);
  const [assignmentState, setAssignmentState] = useState<ASSIGNMENT_STATE>(ASSIGNMENT_STATE.ACTIVE);
  const [hideRetakeCtaOnSubmit, setHideRetakeCtaOnSubmit] = useState(false);

  const triggerTaskEvent = (event: string) => {
    const taskNumber = activeTask?.taskIndex ? (activeTask?.taskIndex + 1) : 1;
    const moduleNumber = activeTask?.moduleIndex ? (activeTask?.moduleIndex + 1) : 1;
    const taskTitle = activeTask?.finalAssignmentTask
      ? activeTask?.finalAssignmentTask?.title : activeTask?.assignmentTask?.title;
    const payload = {
      event,
      userId: profileInfo?.id,
      enterpriseId: profileInfo?.enterpriseID,
      originalTimestamp: dayjs().toISOString(),
      sentAt: dayjs().toISOString(),
      properties: {
        taskId: activeTask?.id,
        taskType: activeTask?.taskType,
        taskNumber,
        taskTitle,
        moduleNumber,
        courseId: registration?.courseID,
        courseSource: registration?.course?.source,
        courseSourceId: registration?.course?.sourceID,
        courseRegistrationId: registration?.id,
        courseTitle: registration?.course?.courseName,
        exclude: isBDMUser(profileInfo.email),
        courseAccessType: registration?.course?.accessType,
      },
    };
    postEvent(payload);
  };

  const trackTaskWithStatus = (status: CourseRegistrationActivityStatus) => {
    if (registration?.course && activeTask) {
      trackCourseRegistrationActivity({
        courseRegistrationID: registration?.id,
        courseID: registration?.course?.id,
        taskID: activeTask.id,
        taskType: activeTask.taskType as TaskType,
        user: profileInfo,
        status,
        triggerTaskEvent,
      });
      if (status === CourseRegistrationActivityStatus.STARTED) {
        taskStarted({
          courseRegistrationId: registration.id,
          moduleNumber: activeTask?.moduleIndex ? activeTask.moduleIndex + 1 : 1,
          taskNumber: activeTask?.taskIndex ? activeTask.taskIndex + 1 : 1,
          courseName: registration.course.courseName,
          courseId: registration.course.id,
        });
      }
    }
  };

  const showRetakeCta = useMemo(() => {
    for (const module of activitiesPerModule) {
      if (module?.attemptedTasks?.length > 0) {
        for (const attemptedTask of module.attemptedTasks) {
          if (attemptedTask?.taskID === activeTask?.id) {
            const showRetakeAssignmentCta = dayjs(attemptedTask?.updatedAt).isBefore(dayjs().subtract(30, 'minutes'));
            if (showRetakeAssignmentCta
              && (attemptedTask?.status === CourseRegistrationActivityStatus.IN_REVIEW)) {
              return true;
            }
          }
        }
      }
    }
    return null;
  },
  [activitiesPerModule, activeTask]);

  /**
   * track assignment submit action.
   * hides retake assignment cta.
   */
  const submitAssignment = () => {
    // TODO: Assignment complete tracked by backend.
    trackTaskWithStatus(CourseRegistrationActivityStatus.IN_REVIEW);
    setAssignmentState(ASSIGNMENT_STATE.DISABLED);
    setHideRetakeCtaOnSubmit(true);
  };

  /**
   *
   *
   */
  const switchToNextTask = () => {
    if (adjacentTasks.nextTask) {
      setActiveTask(adjacentTasks.nextTask);
    }
  };

  /**
   *
   *
   */
  const onAssignmentStart = async () => {
    const registrationId = registration?.id;
    const taskId = activeTask?.id;
    const task = await fetchCourseRegistrationActvityByTaskId(registrationId, taskId);
    setLoading(false);
    if (task) {
      if (task.status === CourseRegistrationActivityStatus.IN_REVIEW) {
        setAssignmentState(ASSIGNMENT_STATE.DISABLED);
      } else if ([
        CourseRegistrationActivityStatus.COMPLETED,
        CourseRegistrationActivityStatus.FAILED,
      ].includes(task.status as CourseRegistrationActivityStatus)) {
        setAssignmentState(ASSIGNMENT_STATE.RESULT);
      }
    } else {
      trackTaskWithStatus(CourseRegistrationActivityStatus.STARTED);
    }
  };

  /**
   *
   * returns the formId with params
   */
  const buildFormId: () => string = () => {
    const activeFormId = activeTask?.taskType === TaskType.FINAL_ASSIGNMENT
      ? activeTask?.finalAssignmentTask?.formId
      : activeTask?.assignmentTask?.formId;

    const data: TypeformRequest = {} as TypeformRequest;
    registration?.course?.modules.forEach((module, moduleIndex) => {
      let assignmentNumber = 0;
      module.tasks.forEach((task) => {
        if ([TaskType.ASSIGNMENT, TaskType.FINAL_ASSIGNMENT].includes(task.taskType as TaskType)) {
          assignmentNumber += 1;
        }
        if (task.id === activeTask?.id) {
          data.moduleNumber = moduleIndex + 1;
          data.moduleName = module.moduleName;
          data.moduleSlug = module.sourceID;
          data.assignmentNumber = assignmentNumber;
          data.assignmentType = task.taskType === TaskType.ASSIGNMENT ? 'weekly' : 'final';
        }
      });
    });
    data.facultySlug = registration?.course?.tags?.[0] || '';
    const params: any = {
      student_id: profileInfo.id,
      course_slug: registration?.course?.sourceID ? encodeURIComponent(registration?.course?.sourceID) : '',
      course_name: registration?.course?.courseName ? encodeURIComponent(registration?.course?.courseName) : '',
      module_slug: encodeURIComponent(data.moduleSlug),
      module_name: encodeURIComponent(data.moduleName),
      module_number: data.moduleNumber,
      course_regid: registration?.id,
      user_name: profileInfo.name,
      locale: 'en',
      assessment_number: data.assignmentNumber,
      faculty_slug: data.facultySlug,
      sub_brand: window.location.hostname.indexOf('localhost') > -1 ? 'dev.d3di9m8c2ff7yj.amplifyapp.com' : window.location.hostname, // TODO: fix
      assessment_type: data.assignmentType,
    };
    const formIdWithParams = Object.keys(params).reduce((acc, cur, index) => {
      if (!index) {
        return `${acc}#${cur}=${params[cur]}`;
      }
      return `${acc}&${cur}=${params[cur]}`;
    }, activeFormId);
    return formIdWithParams || '';
  };

  const retakeAssignmentHandler = () => {
    trackTaskWithStatus(CourseRegistrationActivityStatus.STARTED);
    setAssignmentState(ASSIGNMENT_STATE.ACTIVE);
  };

  useEffect(() => {
    if (activeTask?.taskType
      && [TaskType.ASSIGNMENT, TaskType.FINAL_ASSIGNMENT]
        .includes(activeTask?.taskType as TaskType)
      && profileInfo?.id
      && registration?.id) {
      setFormId(buildFormId());
      setAssignmentState(ASSIGNMENT_STATE.ACTIVE);
      onAssignmentStart();
    }
  }, [activeTask, profileInfo, registration]);

  return (
    loading ? (
      <div className={styles.loadingWrapper}>
        <div className={styles.loading} />
      </div>
    ) : (
      <div className={styles.assignment}>
        {assignmentState === ASSIGNMENT_STATE.DISABLED && (
        <div className={styles.assignmentDisabled}>
          <p>Your assignment is being reviewed</p>
          {showRetakeCta
           && !hideRetakeCtaOnSubmit
          && (
          <button
            className={styles.retakeSubmission}
            type="button"
            onClick={retakeAssignmentHandler}
          >
            Retake
          </button>
          )}
          {adjacentTasks.nextTask && (
          <button
            type="button"
            onClick={switchToNextTask}
          >
            Go to Next Lesson
          </button>
          ) }
        </div>
        )}
        {assignmentState === ASSIGNMENT_STATE.ACTIVE && (
        <Widget
          id={formId}
          className={styles.embed}
          onSubmit={submitAssignment}
        />
        )}
        {assignmentState === ASSIGNMENT_STATE.RESULT
      && (
      <AssignmentAttempted
        retakeAssignment={retakeAssignmentHandler}
      />
      )}
      </div>
    )
  );
};

export default AssignmentViewer;
