import React, { useContext, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import * as styles from '../../styles/learner/ContentSwitcher.module.scss';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import ContentSwitcherTaskItem from './ContentSwitcherTaskItem';
import { CourseModule, CourseRegistrationActivity, CourseRegistrationActivityStatus } from '../../models';

const { Panel } = Collapse;
type AccordionHeaderProps = {
  module: CourseModule,
  moduleIndex: number,
}
const AccordionHeader: React.FC<AccordionHeaderProps> = ({ module, moduleIndex }) => {
  const { activitiesPerModule } = useContext(CourseRegistrationContext);
  const [completedTasks, setCompletedTasks] = useState<Array<CourseRegistrationActivity>>([]);
  useEffect(() => {
    if (activitiesPerModule[moduleIndex]) {
      const tasks = activitiesPerModule[moduleIndex].attemptedTasks
        .filter((task) => task.status === CourseRegistrationActivityStatus.COMPLETED);
      setCompletedTasks(tasks);
    }
  }, [activitiesPerModule]);
  return (
    <div className={`${styles.accordionHeader} ${completedTasks?.length === module?.tasks?.length
      ? styles.completedModule : ''} library-shaw-icon-new`}
    >
      <div className={styles.accordionHeaderTitle}>
        <h6>{module.moduleName}</h6>
        <p>
          {completedTasks.length}
          /
          {module.tasks.length}
          {' '}
          Tasks Completed
        </p>
      </div>
      <div className={styles.arrow}>
        <i className="icon-new-Back-arrow" />
      </div>
    </div>
  );
};

type Props = {
  setAptitudeCompleted: React.Dispatch<React.SetStateAction<boolean>>
}

const ContentSwitcher: React.FC<Props> = ({ setAptitudeCompleted }) => {
  const {
    registration,
    showContentSwitcher,
    setShowContentSwitcher,
  } = useContext(CourseRegistrationContext);

  const course = registration?.course;

  const toggleContentSwitcher = () => {
    setShowContentSwitcher(!showContentSwitcher);
  };

  return (
    <div className={`${styles.contentSwitcherSection} ${
      showContentSwitcher ? '' : styles.float}`}
    >
      <div
        className={styles.contentSwitcherTrigger}
        role="button"
        tabIndex={0}
        onClick={toggleContentSwitcher}
        onKeyDown={(event) => {
          if (event.keyCode === 13) toggleContentSwitcher();
        }}
      >
        <div className={styles.icon}>
          <img src="/images/learner/icon-menu.svg" alt="menu" />
        </div>
        <p>Content List</p>
      </div>
      <div className={styles.contentSwitcherBody}>
        {course?.modules?.length && (
        <Collapse defaultActiveKey={course.modules[0]?.id} accordion>
          {course.modules.map((module, moduleIndex) => (
            <Panel
              showArrow={false}
              header={<AccordionHeader module={module} moduleIndex={moduleIndex} />}
              key={module.id}
            >
              <ul>
                {module.tasks.map((task, taskIndex) => (
                  <ContentSwitcherTaskItem
                    key={task.id}
                    task={task}
                    taskIndex={taskIndex}
                    moduleIndex={moduleIndex}
                    toggleContentSwitcher={toggleContentSwitcher}
                    setAptitudeCompleted={setAptitudeCompleted}
                  />
                ))}
              </ul>
            </Panel>
          ))}
        </Collapse>
        )}
      </div>
    </div>
  );
};

export default ContentSwitcher;
