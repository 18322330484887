import React, { useContext, useEffect } from 'react';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import { CourseAccessType, Task, TaskType } from '../../models';
import * as styles from '../../styles/learner/AdjacentTaskNavigator.module.scss';
import { ActiveTaskType } from '../../types/commons';

/**
 *
 *
 * @return {*}
 */
const AdjacentTaskNavigator: React.FC = () => {
  const {
    activeTask,
    setActiveTask,
    registration,
    adjacentTasks,
    setAdjacentTasks,
  } = useContext(CourseRegistrationContext);

  /**
   *
   *
   * @param {*} task
   */
  const getTaskStructure = (task: Task, taskIndex: number, moduleIndex: number) => ({
    ...task,
    taskIndex,
    moduleIndex,
  });

  /**
   *
   *
   * @param {*} allTasks
   * @return {*}
   */
  const getAdjacentTasks = () => {
    const modules = registration?.course?.modules || [];

    let prevTask = null;
    let nextTask = null;
    for (let i = 0; i < modules?.length; i += 1) {
      const moduleTasks = modules[i]?.tasks;
      for (let j = 0; j < moduleTasks?.length; j += 1) {
        const task = moduleTasks[j];
        if (activeTask?.id === task.id) {
          prevTask = moduleTasks[j - 1] && getTaskStructure(moduleTasks[j - 1], j - 1, i);
          nextTask = moduleTasks[j + 1] && getTaskStructure(moduleTasks[j + 1], j + 1, i);
          return {
            prevTask,
            nextTask,
          };
        }
      }
    }
    return {
      prevTask: null,
      nextTask: null,
    };
  };

  /**
   *
   * auto updates the prev and next tasks
   * when the active task changes.
   */
  const updateTaskNavigationPanel = () => {
    if (activeTask) {
      const { prevTask, nextTask } = getAdjacentTasks();
      setAdjacentTasks({
        prevTask,
        nextTask,
      });
    }
  };

  useEffect(() => {
    updateTaskNavigationPanel();
  }, [activeTask]);

  if (!registration || registration.course?.accessType === CourseAccessType.PRIVATE) {
    return null;
  }

  return (
    <div className={styles.taskNavigationSection}>
      {adjacentTasks?.prevTask ? (
        <button
          type="button"
          onClick={() => setActiveTask(adjacentTasks?.prevTask as ActiveTaskType)}
          className={styles.prev}
        >
          <div className={`${styles.arrow} library-shaw-icon-new`}>
            <i className="icon-new-Back-arrow" />
          </div>
          <div className={styles.prevContent}>
            <h6>Previous</h6>
            <p>
              {adjacentTasks?.prevTask?.taskType === TaskType.VIDEO ? adjacentTasks?.prevTask?.videoTask?.lessonName : ''}
              {adjacentTasks?.prevTask?.taskType === TaskType.ASSIGNMENT ? adjacentTasks?.prevTask?.assignmentTask?.title : ''}
              {adjacentTasks?.prevTask?.taskType === TaskType.FINAL_ASSIGNMENT ? adjacentTasks?.prevTask?.finalAssignmentTask?.title : ''}
            </p>
          </div>
        </button>
      ) : <div />}
      {adjacentTasks?.nextTask && (
      <button
        type="button"
        onClick={() => setActiveTask(adjacentTasks?.nextTask as ActiveTaskType)}
        className={styles.next}
      >
        <div className={styles.nextContent}>
          <h6>Next</h6>
          <p>
            {adjacentTasks?.nextTask?.taskType === TaskType.VIDEO ? adjacentTasks?.nextTask?.videoTask?.lessonName : '' }
            {adjacentTasks?.nextTask?.taskType === TaskType.ASSIGNMENT ? adjacentTasks?.nextTask?.assignmentTask?.title : '' }
            {adjacentTasks?.nextTask?.taskType === TaskType.FINAL_ASSIGNMENT ? adjacentTasks?.nextTask?.finalAssignmentTask?.title : '' }
          </p>
        </div>
        <div className={`${styles.arrow} library-shaw-icon-new`}>
          <i className="icon-new-Back-arrow" />
        </div>
      </button>
      )}
    </div>
  );
};

export default AdjacentTaskNavigator;
