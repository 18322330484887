import React, { useContext, useRef, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { Spin } from 'antd';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import * as styles from '../../styles/learner/CertificateGenerator.module.scss';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import LearnerContext from '../../context/Learner/LearnerContext';
import { CourseGrade } from '../../types/commons';

type Props = {
  grade: CourseGrade | null,
  fetchCertificate: () => Promise<void>,
}
/**
 *
 *
 * @return {*}
 */
const CertificateGenerator: React.FC<Props> = ({ grade, fetchCertificate }) => {
  const svgRef = useRef<HTMLDivElement | null>(null);
  const { profileInfo } = useContext(LearnerContext);
  const { registration } = useContext(CourseRegistrationContext);
  const currentDate = dayjs().format('MMMM D, YYYY');

  /**
   *
   * uses the svg to generate a pdf
   * and uploads the same to s3.
   */
  const generateCertificate = async () => {
    if (typeof window !== 'undefined') {
      const { jsPDF: JsPDF } = await import('jspdf');
      await import('svg2pdf.js');
      const certificateRef:HTMLElement | null | undefined = svgRef?.current?.querySelector('.stringClass');
      const jsPdfInstance = new JsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
      });
      if (certificateRef) {
        html2canvas(certificateRef, {
          width: jsPdfInstance.internal.pageSize.getWidth(),
          height: jsPdfInstance.internal.pageSize.getHeight(),
          scale: 2,
        }).then(async (canvas) => {
          const img = canvas.toDataURL('image/png');

          const doc = jsPdfInstance.addImage(img, 'PNG', 0, 0, jsPdfInstance.internal.pageSize.getWidth(), jsPdfInstance.internal.pageSize.getHeight());
          const pdf = doc.output('arraybuffer');

          await Storage.put(`certficates/${registration?.id}/certificate`, pdf, {
            contentType: 'application/pdf',
            level: 'public',
          });
          fetchCertificate();
        });
      }
    }
  };

  useEffect(() => {
    if (registration?.id) {
      generateCertificate();
    }
  }, [registration]);

  return (
    <div
      ref={svgRef}
      className={styles.svgWrapper}
    >
      <Spin />
      <div className={`${styles.certificateContainer} stringClass`}>
        <div className={styles.certificateWrapper}>
          <img
            className={styles.brandLogo}
            alt="logo"
            src="/images/learner/certificate/brand-image.png"
          />
          <p className={styles.recommendationText}>
            Upon the recommendation of the Faculty and
            by the joint authority of the Academic Board
            of Upskillist and CPD, Upskillist has conferred the
          </p>
          <h5 className={styles.courseName}>Diploma</h5>
          <p className={styles.fillerText}>in</p>
          <h5 className={styles.courseName}>{registration?.course?.courseName}</h5>
          <h6 className={styles.userName}>{profileInfo.name}</h6>
          <p className={styles.upskillCert}>
            Who has successfully completed the course and acquired the
            <span> Upskillist Diploma</span>
          </p>
          <p className={styles.gradeDetails}>
            Grade:
            <span>{grade}</span>
          </p>
          <p className={styles.gradeDetails}>
            Dated:
            <span>{currentDate}</span>
          </p>
          <div className={styles.certDetails}>
            <p>In witness whereof, the seal of Upskillist is impressed upon this parchment.</p>
            <p className={styles.awdDetails}>
              Certificate of completion Awarded By CPD

            </p>
          </div>
          <div className={styles.certificateLogos}>
            <img
              className={styles.hcdLogo}
              alt="HCD"
              src="/images/learner/certificate/HCD-logo.png"
            />
          </div>
          <p>Globally Certified By</p>
          <img
            className={styles.cpdLogo}
            alt="cpd"
            src="/images/learner/certificate/CPD.png"
          />
        </div>
      </div>
    </div>
  );
};

export default CertificateGenerator;
