import React, { useContext, useEffect, useState } from 'react';
import * as styles from '../../styles/learner/CertificateTabView.module.scss';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import FinalAssignmentItem from './FinalAssignmentItem';
import CertificateDisplay from './CertificateDisplay';
import { ActiveTaskType, AptitudeResult } from '../../types/commons';
import { CourseModule } from '../../models';
import CourseProgressDisplay from './CourseProgressDisplay';
import AptitudeItem from './AptitudeItem';
import { fetchAssignmentResponseForCourseByTaskId } from '../DataStore/AssignmentResponseService';

type Props = {
  switchTab: (t: string) => void,
}
/**
 *
 *
 * @return {*}
 */
const CertificatesTabView:React.FC<Props> = (props) => {
  const { switchTab } = props;
  const { registration } = useContext(CourseRegistrationContext);
  const [finalAssignments, setFinalAssignments] = useState<Array<ActiveTaskType>>([]);
  const [certificateReady, setCertificateReady] = useState(false);
  const [aptitudeDetails, setAptitudeDetails] = useState<AptitudeResult | null>(null);

  const fetchAptitudeResponse = async () => {
    const regId = registration?.id;
    const aptitudeResult = await fetchAssignmentResponseForCourseByTaskId(regId, regId);
    if (aptitudeResult) {
      setAptitudeDetails(aptitudeResult);
    } else {
      setAptitudeDetails(null);
    }
  };

  useEffect(() => {
    if (registration?.course) {
      const moduleAssignments = registration.course.modules
        .reduce((acc: Array<ActiveTaskType>, cur: CourseModule) => {
          cur.tasks.forEach((task, taskIndex) => {
            if (task.finalAssignmentTask) {
              acc.push({
                ...task,
                taskIndex,
              });
            }
          });
          return acc;
        }, []);
      setFinalAssignments(moduleAssignments);
      fetchAptitudeResponse();
    }
  }, [registration]);

  return (
    <div className={styles.certificateTabView}>
      {
       aptitudeDetails && (
       <AptitudeItem
         aptitudeDetails={aptitudeDetails}
       />
       )
      }
      <div className={styles.certificateListing}>
        {finalAssignments.map((task, index) => (
          <div
            className={styles.finalAssignmentItem}
            key={task?.id}
          >
            <FinalAssignmentItem
              task={task}
              moduleNumber={index + 1}
              switchTab={switchTab}
            />
          </div>
        ))}
      </div>
      <CourseProgressDisplay
        finalAssignments={finalAssignments}
        switchTab={switchTab}
        certificateReady={certificateReady}
      />
      <CertificateDisplay
        certificateReady={certificateReady}
        setCertificateReady={setCertificateReady}
      />
    </div>
  );
};

export default CertificatesTabView;
