import React, {
  useContext, useEffect, useState,
} from 'react';
import { Widget } from '@typeform/embed-react';
import * as styles from '../../styles/learner/AptitudeViewer.module.scss';
import { CourseRegistrationWithCourse } from '../../types/commons';
import { createCourseRegistrationActivity } from '../DataStore/CourseRegistrationActivityService';
import { CourseRegistrationActivityStatus, TaskType } from '../../models';
import LearnerContext from '../../context/Learner/LearnerContext';
import { TypeformRequest } from './AssignmentViewer';
import { toaster } from '../../services/utils';

type Props = {
  aptiTypeformId: string,
  registration: CourseRegistrationWithCourse | null,
  setAptitudeCompleted: React.Dispatch<React.SetStateAction<boolean>>,
  showContentSwitcher: boolean
}

const AptitudeViewer:React.FC<Props> = ({
  aptiTypeformId,
  registration,
  setAptitudeCompleted,
  showContentSwitcher,
}) => {
  const [formId, setFormId] = useState<string>('');

  const { profileInfo } = useContext(LearnerContext);

  const submitHandler = () => {
    createCourseRegistrationActivity({
      courseRegistrationID: registration?.id,
      courseID: registration?.courseID,
      user: profileInfo,
      taskID: registration?.id,
      taskType: TaskType.FINAL_ASSIGNMENT,
      status: CourseRegistrationActivityStatus.IN_REVIEW,
    });
    toaster('Task Created');
    setAptitudeCompleted(true);
  };

  const buildFormID = () => {
    const activeFormId = aptiTypeformId;

    const data: TypeformRequest = {} as TypeformRequest;
    const firstModule = registration?.course?.modules?.[0];
    data.moduleNumber = 1;
    data.moduleName = firstModule?.moduleName || '';
    data.moduleSlug = firstModule?.sourceID || '';
    data.assignmentNumber = 0;
    data.assignmentType = 'final';
    data.facultySlug = registration?.course?.tags?.[0] || '';

    const params: any = {
      student_id: profileInfo.id,
      course_slug: registration?.course?.sourceID ? encodeURIComponent(registration?.course?.sourceID) : '',
      course_name: registration?.course?.courseName ? encodeURIComponent(registration?.course?.courseName) : '',
      module_slug: encodeURIComponent(data.moduleSlug),
      module_name: encodeURIComponent(data.moduleName),
      module_number: data.moduleNumber,
      course_regid: registration?.id,
      user_name: profileInfo.name,
      locale: 'en',
      assessment_number: data.assignmentNumber,
      faculty_slug: data.facultySlug,
      sub_brand: window.location.hostname.indexOf('localhost') > -1 ? 'dev.d3di9m8c2ff7yj.amplifyapp.com' : window.location.hostname, // TODO: fix
      assessment_type: data.assignmentType,
    };

    const formIdWithParams = Object.keys(params).reduce((acc, cur, index) => {
      if (!index) {
        return `${acc}#${cur}=${params[cur]}`;
      }
      return `${acc}&${cur}=${params[cur]}`;
    }, activeFormId);
    return formIdWithParams || '';
  };

  const skipAptitude = () => {
    setAptitudeCompleted(true);
  };

  useEffect(() => {
    if (aptiTypeformId && registration && profileInfo?.id) {
      setFormId(buildFormID());
    }
  }, [aptiTypeformId, registration, profileInfo]);

  return (
    <div className={styles.aptitudeViewer}>
      <Widget
        id={formId}
        className={styles.embed}
        onSubmit={submitHandler}
      />
      <div className={`${styles.skipAptitudeCtaContainer} ${showContentSwitcher ? styles.activeContentSwitcher : ''}`}>
        <button className={styles.skipAptitudeCta} onClick={skipAptitude} type="button">
          Skip to Lesson 1 &nbsp;&nbsp;
          <img src="/images/learner/skip-cta-arrow.png" alt="Right pointing arrow with a straight line" />
        </button>
      </div>
    </div>
  );
};

export default AptitudeViewer;
