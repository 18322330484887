import React, { useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import * as styles from '../../styles/learner/LessonViewer.module.scss';
import LearnerContext from '../../context/Learner/LearnerContext';
import {
  trackCourseRegistrationActivity,
} from './Services/CourseRegistrationService';
import { CourseRegistrationActivityStatus, TaskType } from '../../models';
import { taskCompleted, taskStarted } from '../../services/TrackActionService';
import { isBDMUser, postEvent } from '../../services/utils';

type Props = {
  handleVideoTask: () => Promise<void>
}
/**
 *
 *
 * @return {*}
 */
const LessonViewer:React.FC<Props> = ({ handleVideoTask }) => {
  const {
    activeTask, registration, player,
    adjacentTasks, setActiveTask,
  } = useContext(CourseRegistrationContext);
  const { profileInfo } = useContext(LearnerContext);

  const triggerTaskEvent = (event:string) => {
    const taskNumber = activeTask?.taskIndex ? (activeTask?.taskIndex + 1) : 1;
    const moduleNumber = activeTask?.moduleIndex ? (activeTask?.moduleIndex + 1) : 1;
    const payload = {
      event,
      userId: profileInfo?.id,
      enterpriseId: profileInfo?.enterpriseID,
      originalTimestamp: dayjs().toISOString(),
      sentAt: dayjs().toISOString(),
      properties: {
        taskId: activeTask?.id,
        taskType: activeTask?.taskType,
        taskNumber,
        taskTitle: activeTask?.videoTask?.lessonName,
        moduleNumber,
        courseId: registration?.courseID,
        courseSource: registration?.course?.source,
        courseSourceId: registration?.course?.sourceID,
        courseRegistrationId: registration?.id,
        courseTitle: registration?.course?.courseName,
        exclude: isBDMUser(profileInfo.email),
        courseAccessType: registration?.course?.accessType,
      },
    };
    postEvent(payload);
  };

  const trackTaskWithStatus = (status: CourseRegistrationActivityStatus) => {
    if (registration?.course && activeTask) {
      trackCourseRegistrationActivity({
        courseRegistrationID: registration.id,
        courseID: registration.course.id,
        taskID: activeTask.id,
        taskType: activeTask.taskType as TaskType,
        user: profileInfo,
        status,
        triggerTaskEvent,
      });
      if (status === CourseRegistrationActivityStatus.STARTED) {
        taskStarted({
          courseRegistrationId: registration.id,
          moduleNumber: activeTask?.moduleIndex ? activeTask.moduleIndex + 1 : 1,
          taskNumber: activeTask?.taskIndex ? activeTask.taskIndex + 1 : 1,
          courseName: registration.course.courseName,
          courseId: registration.course.id,
        });
      }
      if (status === CourseRegistrationActivityStatus.COMPLETED) {
        taskCompleted({
          courseRegistrationId: registration?.id,
          moduleNumber: activeTask?.moduleIndex ? activeTask.moduleIndex + 1 : 1,
          taskNumber: activeTask?.taskIndex ? activeTask.taskIndex + 1 : 1,
          courseName: registration?.course?.courseName,
          courseId: registration?.course?.id,
        });
      }
    }
  };

  /**
   *
   * if the lesson progress is more than 80%.
   * fire courseRegistrationActivity.
   *
   */
  const checkLessonCompletion = () => {
    let completed = false;
    player?.on('progress', (evt) => {
      if (evt.percent > 0.3 && !completed) {
        completed = true;
        trackTaskWithStatus(CourseRegistrationActivityStatus.COMPLETED);
      }
    });
    player?.on('ended', () => {
      if (adjacentTasks?.nextTask) {
        setActiveTask(adjacentTasks?.nextTask);
      }
    });
  };

  const checkLessonStart = () => {
    player?.on('loaded', () => {
      trackTaskWithStatus(CourseRegistrationActivityStatus.STARTED);
    });
  };

  useEffect(() => {
    if (profileInfo?.id) {
      if (player) {
        checkLessonStart();
        checkLessonCompletion();
      } else {
        handleVideoTask();
      }
    }
  }, [player, profileInfo]);

  return (
    <div className={styles.video}>
      <div id="video-player" />
    </div>
  );
};

export default LessonViewer;
