import React, {
  FC, useState, useEffect, useContext, useMemo,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { navigate } from 'gatsby';
import * as styles from '../../styles/learner/CourseProgressDisplay.module.scss';
import { ActiveTaskType } from '../../types/commons';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import { fetchAssignmentResponseForCourseByTaskId } from '../DataStore/Services';
import { AssignmentResponse } from '../../models';

interface Props {
  finalAssignments: ActiveTaskType[],
  switchTab: (t: string) => void,
  certificateReady: boolean
}

const CourseProgressDisplay:FC<Props> = ({
  finalAssignments,
  switchTab,
}) => {
  const { registration } = useContext(CourseRegistrationContext);
  const [assignmentResponse, setAssignmentResponse] = useState<
  AssignmentResponse[]>([] as AssignmentResponse[]);

  const checkAssignmentCompletion = async () => {
    const regId = registration?.id;
    const promises = finalAssignments.map((task) => (
      fetchAssignmentResponseForCourseByTaskId(regId, task?.id)
    ));
    const finalAssignmentResponse = await Promise.all(promises);
    const assignmentData:any = [];
    finalAssignmentResponse.forEach((response:AssignmentResponse) => {
      if (response) {
        assignmentData.push(response);
      } else {
        assignmentData.push([]);
      }
    });
    setAssignmentResponse(assignmentData);
  };

  /*
  * checks if all the assignments are completed and have an average score of 50 or above each
  */
  const checkEligibilityToViewCertificate = () => {
    if (!assignmentResponse?.length) return false;
    return assignmentResponse.every((assignment) => {
      if (!assignment?.score) return false;
      const score = Math.ceil((assignment?.score / assignment?.totalScore) * 100);
      if (score < 50) return false;
      return true;
    });
  };

  const calculateAveragePercentage = () => {
    let sum = 0;
    const totalModules = assignmentResponse.length || 4;
    assignmentResponse.forEach((response) => {
      if (response?.totalScore) {
        sum += Math.ceil((response?.score
          / response?.totalScore) * 100);
      }
    });
    return Math.floor(sum / totalModules);
  };

  const navigateToCourseCertificate = () => {
    const regID = registration?.id;
    navigate(`/course-certificate/${regID}/`);
  };

  useEffect(() => {
    if (finalAssignments?.length) {
      checkAssignmentCompletion();
    }
  }, [finalAssignments]);

  const averageScore = useMemo(calculateAveragePercentage, [assignmentResponse]);

  const isEligibleToViewCertificate = useMemo(checkEligibilityToViewCertificate,
    [assignmentResponse]);

  const getProgressRingStyle = () => {
    const moduleLength = assignmentResponse.length;
    if (moduleLength === 1) {
      return styles.full;
    } if (moduleLength === 2) {
      return styles.half;
    }
    return styles.quarter;
  };
  return (
    <div className={styles.summary}>
      <div className={styles.part1}>
        <div className={styles.scoreHeading}>
          Diploma Score
        </div>
        <div className={styles.assessmentsCourseProgressIndicator}>
          <div className={styles.progressRing}>
            {assignmentResponse.map((module) => (

              <div
                key={uuidv4()}
                className={`${getProgressRingStyle()}
            ${module.score ? styles.active : ''}`}
              />
            ))}

            <div className={styles.cutout}>
              <h3>
                {averageScore || 0}
                {' '}
                %
              </h3>
            </div>
          </div>
          <ul
            className={styles.legend}
          >
            {assignmentResponse.map((module, moduleIndex) => {
              let percentage = 0;
              if (module?.score) {
                percentage = Math.ceil((module?.score / module?.totalScore) * 100);
              }
              return (
                <li
                  key={uuidv4()}
                  className={styles.legendList}
                >
                  <span>
                    Module
                    {moduleIndex + 1}
                  </span>
                  {' '}
                  {percentage}
                  %
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={styles.part2}>
        <p className={styles.subjectHeading}>Diploma in</p>
        <div className={styles.subject}>{registration?.course?.courseName}</div>
        <div className={styles.certifiedWrapper}>
          <div className={styles.cpdLogoSection}>
            <p>Globally Certified by:</p>
            <img src="/images/learner/cpd-new.png" alt="cpd" />
          </div>
        </div>
        <div className={styles.completion}>
          {isEligibleToViewCertificate
            ? `You have completed all your final assignments.
           Click on View Certificate to view your course certificate`
            : 'Complete all your final assignments and earn your diploma'}
        </div>
        {isEligibleToViewCertificate
          ? (
            <button
              id="learner-course-registration-certificates-view-now"
              className={styles.continueBtn}
              type="button"
              onClick={navigateToCourseCertificate}
            >
              View Certificate
            </button>
          )
          : (
            <button
              id="learner-course-registration-certificates-continue-learning"
              onClick={() => switchTab('content')}
              className={styles.continueBtn}
              type="button"
            >
              Continue Learning
            </button>
          )}
      </div>
    </div>
  );
};

export default CourseProgressDisplay;
