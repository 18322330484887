import React, {
  useContext, useEffect, useState,
} from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from '../../styles/learner/ContentSwitcherTaskItem.module.scss';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import {
  CourseRegistrationActivity, CourseRegistrationActivityStatus, Task, TaskType,
} from '../../models';
import { fetchAssignmentResponseForCourseByTaskId } from '../DataStore/AssignmentResponseService';
import { getGradeFromPercentage } from './Services/CourseRegistrationService';
import { AptitudeResult, CourseGrade } from '../../types/commons';

type Props = {
  task: Task,
  taskIndex: number,
  toggleContentSwitcher: () => void,
  setAptitudeCompleted: React.Dispatch<React.SetStateAction<boolean>>
  moduleIndex: number
}

type TaskDetails = {
  taskType: CourseRegistrationActivity['taskType'],
  status: CourseRegistrationActivity['status'],
  assignment?: AptitudeResult & {
    percentage: number,
    grade: CourseGrade
  }
}
/**
 *
 *
 * @return {*}
 */
const ContentSwitcherTaskItem: React.FC<Props> = (props) => {
  const {
    task, taskIndex, toggleContentSwitcher, setAptitudeCompleted, moduleIndex,
  } = props;
  const {
    registration, activeTask, setActiveTask, activitiesPerModule,
  } = useContext(CourseRegistrationContext);
  const [taskDetails, setTaskDetails] = useState<TaskDetails | null>(null);

  /**
   *
   *
   */
  const setTask = () => {
    if (window.innerWidth < 768) {
      toggleContentSwitcher();
    }
    setAptitudeCompleted(true);
    setActiveTask({
      ...task,
      taskIndex,
      moduleIndex,
    });
  };

  const getTaskDetails = () => {
    const attemptedTasks = activitiesPerModule?.[moduleIndex]?.attemptedTasks;
    const currentTaskId = task?.id;
    if (!attemptedTasks?.length) return null;
    return attemptedTasks.filter((attemptedTask) => attemptedTask?.taskID === currentTaskId)?.[0];
  };

  const fetchAssignmentResult = async () => {
    const regId = registration?.id;
    const taskId = task?.id;
    const assignmentResult = await fetchAssignmentResponseForCourseByTaskId(regId, taskId);
    return assignmentResult;
  };

  const handleVideoTask = (currentTask:CourseRegistrationActivity) => {
    const taskType = currentTask?.taskType;
    const status = currentTask?.status;
    const data = {
      taskType,
      status,
    };
    setTaskDetails(data);
  };

  const handleAssignmentTask = async (currentTask:CourseRegistrationActivity) => {
    const taskType = currentTask?.taskType || '';
    const status = currentTask?.status || '';
    let result = null;
    const assignmentResult = await fetchAssignmentResult();
    if (assignmentResult) {
      result = { ...assignmentResult };
      const percentage = Math.ceil((result?.score / result?.totalScore) * 100);
      const grade = getGradeFromPercentage(percentage);
      result.percentage = percentage;
      result.grade = grade;
    }
    setTaskDetails({
      taskType,
      status,
      assignment: result,
    });
  };

  const fetchTaskDetails = () => {
    try {
      if (registration?.id && activitiesPerModule?.length > 0 && task?.id && moduleIndex >= 0) {
        if (activitiesPerModule?.[moduleIndex]?.attemptedTasks?.length > 0) {
          const currentTask = getTaskDetails();
          if (currentTask) {
            if (currentTask?.taskType === TaskType.VIDEO) {
              handleVideoTask(currentTask);
            } else {
              handleAssignmentTask(currentTask);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (registration?.id && activitiesPerModule?.length > 0 && task?.id && moduleIndex >= 0) {
      if (activitiesPerModule?.[moduleIndex]?.attemptedTasks?.length > 0) {
        fetchTaskDetails();
      }
    }
  }, [registration, activitiesPerModule, moduleIndex, task?.id]);

  return (
    <li
      className={`${(activeTask?.id === task?.id ? styles.active : '')} ${styles.contentSwitcherTaskItem}`}
      onClick={() => setTask()}
      role="presentation"
      onKeyDown={(event) => {
        if (event.keyCode === 13) setTask();
      }}
    >
      <div className={styles.taskContent}>
        <h5>
          {task.taskType === TaskType.VIDEO ? task?.videoTask?.lessonName : ''}
          {task.taskType === TaskType.ASSIGNMENT ? task?.assignmentTask?.title : ''}
          {task.taskType === TaskType.FINAL_ASSIGNMENT ? task?.finalAssignmentTask?.title : ''}
        </h5>
        <p>{task.videoTask ? 'Class recording' : 'Graded activity'}</p>
      </div>
      <div className={styles.rightContent}>
        {task.taskType === TaskType.VIDEO
        && (taskDetails?.status === CourseRegistrationActivityStatus.COMPLETED
          ? (
            <StaticImage
              alt="completed-task"
              src="../../../static/images/learner/completed-task.png"
            />
          )
          : (
            <div className={styles.taskIcon}>
              <i className="icon-new-Play-1" />
            </div>
          ))}
        {(task.taskType === TaskType.ASSIGNMENT
         || task.taskType === TaskType.FINAL_ASSIGNMENT)
            && (
            <>
              {!taskDetails?.assignment && (
              <div className={styles.taskIcon}>
                <i className="icon-new-Edit2" />
              </div>
              )}
              {taskDetails?.assignment && (taskDetails.assignment.grade === CourseGrade.FAIL
                ? <p className={styles.retry}>Retry</p>
                : (
                  <div className={styles.score}>
                    <p>{`${taskDetails.assignment?.percentage}%`}</p>
                    <p>Scored</p>
                  </div>
                ))}
            </>
            )}
      </div>
    </li>
  );
};

export default ContentSwitcherTaskItem;
