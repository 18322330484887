import React, { useContext, useRef } from 'react';
import { Storage } from 'aws-amplify';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';
import * as styles from '../../styles/learner/CertificateActions.module.scss';
import { downloadBlob } from './Services/CourseRegistrationService';

// enum MEDIA {
//   FACEBOOK,
//   TWITTER,
//   LINKEDIN,
// }

type Props = {
  certificateReady: boolean,
  setCertificateReady: React.Dispatch<React.SetStateAction<boolean>>,
  regerateCertificate: () => void,
}

/**
 *
 *
 * @return {*}
 */
const CertificateActions: React.FC<Props> = ({
  certificateReady,
  setCertificateReady,
  regerateCertificate,
}) => {
  const linkRef = useRef<HTMLInputElement | null>(null);
  const { registration } = useContext(CourseRegistrationContext);

  const domain = window.location.origin;

  const certificateUrl = encodeURIComponent(
    `${domain}/course-certificate/${registration?.id}`,
  );

  /**
   *
   * downloads the certificate from S3
   */
  const downloadCertificate = async () => {
    try {
      setCertificateReady(false);
      const fileKey = `certficates/${registration?.id}/certificate`;
      const result = await Storage.get(fileKey, { download: true, level: 'public' }) as any;
      await downloadBlob(result.Body, 'certificate');
      setCertificateReady(true);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   *
   * opens the linkedIn page with
   * details of the shareable certficate filled in.
   *
   */
  // const addToLinkedInProfile = () => {
  //   const issueYear = new Date().getFullYear(); // need to check
  //   const issueMonth = new Date().getMonth(); // need to check

  //   const linkedInUrl = 'https://www.linkedin.com/profile/add?startTask=';
  //   const urlToNavigate = `${linkedInUrl}CERTIFICATION_NAME`
  //   + `&name=${registration?.course?.courseName}`
  //   + '&organizationId=5038433'
  //   + `&issueYear=${issueYear}&issueMonth=${issueMonth}`
  //   + `&expirationYear=${issueYear + 5}`
  //   + `&expirationMonth=${issueMonth}`
  //   + `&certUrl=${certificateUrl}`
  //   + `&certId=${registration?.id}`;
  //   window.open(urlToNavigate);
  // };

  /**
   *
   * Copies the content from the input
   * to clipboard.
   */
  const handleCopyToClipboard = () => {
    const linkRefObject = linkRef?.current;
    linkRefObject?.select();
    document.execCommand('copy');
  };

  /**
   *
   * handles sharing the certificate on
   * different social media platforms.
   */
  // const shareCertificate = (cta: MEDIA) => {
  //   let urlToNavigate = '';
  //   switch (cta) {
  //     case MEDIA.FACEBOOK:
  //       urlToNavigate = 'https://www.facebook.com/sharer/sharer.php?'
  //       + `&u=${certificateUrl}`;
  //       break;
  //     case MEDIA.LINKEDIN:
  //       urlToNavigate = 'https://www.linkedin.com/sharing/share-offsite?mini=true'
  //       + `&url=${certificateUrl}`;
  //       break;
  //     case MEDIA.TWITTER:
  //       urlToNavigate = 'https://twitter.com/intent/tweet?'
  //       + `&url=${certificateUrl}`;
  //       break;
  //     default:
  //       urlToNavigate = decodeURIComponent(certificateUrl);
  //   }
  //   window.open(urlToNavigate);
  // };

  return (
    <div className={`${styles.actionContainer} library-shaw-icon-new`}>
      <h5 className={styles.heading}>
        Share your Diploma certificate
      </h5>
      <p className={styles.info}>
        Add more value to your profile by sharing
        verifiable diploma on your LinkedIn profile.
      </p>
      <button
        type="button"
        id="learner-course-registration-certificates-download-now"
        className={styles.downloadCta}
        onClick={downloadCertificate}
        disabled={!certificateReady}
      >
        <i className="icon-new-Download1" />
        <p>Download now!</p>
      </button>
      {/* <button
        type="button"
        className={styles.linkedInShare}
        onClick={addToLinkedInProfile}
      >
        <i
          className="icon-new-linkedin"
        />
        <p>Add to Profile </p>
      </button> */}
      {/* <p className={styles.shareCertificateTitle}>
        Share your certificate on
      </p> */}
      {/* <div className={styles.socialMediaIconsConatiner}>
        <button
          type="button"
          onClick={() => shareCertificate(MEDIA.FACEBOOK)}
        >
          <p className={styles.socialIconShare}>
            <i className="icon-new-Facebook1" />
          </p>
        </button>
        <button
          type="button"
          onClick={() => shareCertificate(MEDIA.LINKEDIN)}
        >
          <p className={styles.socialIconShare}>
            <i className="icon-new-linkedin" />
          </p>
        </button>
        <button
          type="button"
          onClick={() => shareCertificate(MEDIA.TWITTER)}
        >
          <p className={styles.socialIconShare}>
            <i className="icon-new-twitter" />
          </p>
        </button>
      </div> */}
      <p className={styles.shareCertificateTitle}>
        Share your certificate link
      </p>
      <div className={styles.copyButtonContainer}>
        {/* <i className={linkIcon} alt="link-icon" className="linkIcon" /> */}
        <input
          ref={linkRef}
          defaultValue={decodeURIComponent(certificateUrl)}
        />
        <button
          id="learner-course-registration-certificates-copy"
          type="button"
          onClick={handleCopyToClipboard}
        >
          Copy
        </button>
      </div>
      {certificateReady && (
      <button
        id="learner-course-registration-certificates-regenerate-certificate"
        type="button"
        className={styles.regerateCertificateCta}
        onClick={regerateCertificate}
      >
        <p>Regenerate Certificate</p>
      </button>
      )}
    </div>
  );
};

export default CertificateActions;
