import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from '../../styles/learner/LessonResourceItem.module.scss';
import { downloadResourceItem } from './Services/CourseRegistrationService';
import { Resource } from '../../models';

type Props = {
  resource: Resource,
  resourceIndex: number
}

enum LessonResourceType {
  DOCUMENT = 'DOCUMENT'
}

const LessonResourceItem: React.FC<Props> = ({ resource, resourceIndex }) => {
  const openFile = () => {
    if (!resource?.documentResource?.link?.href) return;
    downloadResourceItem(resource.documentResource.link.href);
  };
  return (
    resource.resourceType === LessonResourceType.DOCUMENT
      ? (
        <li className={styles.lessonResourceItem}>
          <button
            type="button"
            onClick={openFile}
          >
            {resourceIndex % 2 === 0
              ? (
                <StaticImage
                  src="../../../static/images/learner/lesson-slides.png"
                  alt="lesson-slides"
                  placeholder="blurred"
                />
              )
              : (
                <StaticImage
                  src="../../../static/images/learner/lesson-notes.png"
                  alt="lesson-notes"
                  placeholder="blurred"
                />
              )}
          </button>
          <p>{resource?.documentResource?.link?.title}</p>
        </li>
      ) : (
        <li className={styles.lessonResourceItem}>
          <p>{resource?.videoResource?.name}</p>
          <button
            type="button"
            className="library-shaw-icon-new"
          >
            <i className="icon-new-Play" />
            Play
          </button>
        </li>
      )
  );
};

export default LessonResourceItem;
