import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Resource } from '../../models';
import * as styles from '../../styles/learner/ActiveTaskResourceItem.module.scss';
import { downloadResourceItem } from './Services/CourseRegistrationService';

type Props = {
  resource: Resource,
  resourceIndex: number
}
/**
 *
 *
 * @return {*}
 */
const ActiveTaskResourceItem:React.FC<Props> = ({
  resource,
  resourceIndex,
}) => {
  const openFile = () => {
    if (!resource?.documentResource?.link?.href) return;
    downloadResourceItem(resource.documentResource.link.href);
  };
  return (
    <li className={styles.activeTaskResourceItem}>
      <button
        type="button"
        onClick={openFile}
      >
        {resourceIndex % 2 === 0
          ? (
            <StaticImage
              src="../../../static/images/learner/lesson-slides.png"
              alt="lesson-slides"
              placeholder="blurred"
            />
          )
          : (
            <StaticImage
              src="../../../static/images/learner/lesson-notes.png"
              alt="lesson-notes"
              placeholder="blurred"
            />
          )}
      </button>
      <p>{resource?.documentResource?.link?.title}</p>
    </li>
  );
};

export default ActiveTaskResourceItem;
