import React, { useContext } from 'react';
import { Tabs } from 'antd';
import * as styles from '../../styles/learner/CourseMaterialsTabView.module.scss';
import StarterKit from './StarterKit';
import CourseMaterialsPack from './CourseMaterialsPack';
import CourseRegistrationContext from '../../context/Learner/CourseRegistrationContext';

const { TabPane } = Tabs;

/**
 *
 *
 * @return {*}
 */
const CourseMaterialsTabView: React.FC = () => {
  const { registration } = useContext(CourseRegistrationContext);
  const course = registration?.course;
  return (
    <div className={styles.courseMaterialsTabView}>
      <Tabs tabPosition="left" className={styles.courseMaterialsTabSwitcher} defaultActiveKey="1">
        {course?.starterPack && (
        <TabPane tab="Starter kit" key="1">
          <StarterKit course={course} />
        </TabPane>
        )}
        {course?.modules?.length && course.modules.map((module, moduleIndex) => (
          <TabPane tab={`Module ${moduleIndex + 1}`} key={module.id}>
            <CourseMaterialsPack tasks={module.tasks} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default CourseMaterialsTabView;
